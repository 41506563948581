import React from "react";
import arrows from '../../assets/images/arrows.svg';
import "./LoaderButton.css";

const loaderButton =  ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  customStyles,
  ...props
}) => {

  const styles = {
    backgroundColor: "#1e3553",
    color: "white",
    ...customStyles
  }

  return (
    <div style={{ ...styles }} className={`LoaderButton ${className}`} disabled={disabled || isLoading} {...props}>
      {isLoading && <img alt="spinner" src={arrows} className="spinning" />}
      {!isLoading ? text : loadingText}
    </div>
  )
}

export default loaderButton
