import React from 'react';
import './Toggle.scss';

export default function Toggle(props) {
  const { states, currentState, onToggle } = props;
  return (
    <div className="toggle" onClick={onToggle}>
      {states.map(state => {
        return (
          <button key={state.id} className={'button ' + (state.id === currentState ? 'active' : '')}>
            {state.name}
          </button>
        );
      })}
    </div>
  );
}
