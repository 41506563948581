import React from 'react';
import moment from 'moment';
import { decode } from 'html-entities';
import './News.scss';
import Toggle from '../Toggle/Toggle.js';
import translations from '../../../translations.json'
import 'moment/locale/de'
import 'moment/locale/en-in'


export default function News(props) {
  const { news, locale, toggleStates, activeToggleState, onToggle, activeLanguage, listClasses, newsItemClasses } = props;

  const listItems = (news || []).map(item => {
    const { date_gmt, link } = item;
    const datePost = moment(date_gmt).locale(locale).format("LL");
    return (
      <a rel="noreferrer" href={link} target="_blank" className={`news__item ${newsItemClasses}`} key={item.id}>
        <h3 className="news__title">{decode(item.title.rendered)}</h3>
        <p className="news__time text">{datePost}</p>
      </a>
    );
  });
  return (
    <div className="news">
      <header className="news__header">
        <h2 className="dftitle news__title">{translations[activeLanguage].values['newsTitle']}</h2>
          {toggleStates ? <Toggle states={toggleStates} currentState={activeToggleState} onToggle={onToggle} />: null}
      </header>
      <div className={`news__list ${listClasses}`}>{listItems}</div>
    </div>
  );
}

News.defaultProps = {
  listClasses:"", 
  newsItemClasses:""
}