import React, { Component } from "react";
import LoginContainer from './components/pages/LoginContainer'
import './scss/global.scss'
import './scss/variables.scss'
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: 0,
      locale: "en",
      user: {},
      error: null,
      toolbarHidden: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem('activeLanguage') === null) {
      const browserLanguage = navigator.language ? navigator.language.substr(0, 2) : navigator.userLanguage.substr(0, 2)
      switch (browserLanguage) {
        case "de":
          localStorage.setItem('activeLanguage', 1);
          break;
        default:
          localStorage.setItem('activeLanguage', 0);
      }
    }
    if (+localStorage.getItem('activeLanguage')) this.setState({ activeLanguage: localStorage.activeLanguage });

    if (localStorage.getItem('locale') === null) {
      const browserLanguage = navigator.language ? navigator.language.substr(0, 2) : navigator.userLanguage.substr(0, 2)
      switch (browserLanguage) {
        case "de":
          localStorage.setItem('locale', "de");
          break;
        default:
          localStorage.setItem('locale', "en");
      }
    }
    if (localStorage.getItem('locale')) this.setState({ locale: localStorage.locale });
  }

  onToggleToolbarVisibility = () => {
    this.setState({ toolbarHidden: !this.state.toolbarHidden });
  }

  onChangeLanguage = chosenLanguage => {
    if (chosenLanguage !== this.state.activeLanguage) {
      localStorage.setItem('activeLanguage', chosenLanguage);
      this.setState({ activeLanguage: chosenLanguage });
      if (chosenLanguage === 0){
        localStorage.setItem('locale', "en");
        this.setState({ locale: "en" });
      } else {
        localStorage.setItem('locale', "de");
        this.setState({ locale: "de" });
      }
    }
  }


  render() {
    const props = {
      toolbarHidden: this.state.toolbarHidden,
      toggleToolbarVisibility: this.onToggleToolbarVisibility,
      isAuthenticated: this.state.isAuthenticated,
      isAuthenticating: this.state.isAuthenticating,
      error: this.state.error,
      isOpenLangPicker: this.state.isOpenLangPicker,
      activeLanguage: this.state.activeLanguage,
      locale: this.state.locale,
      onChangeLanguage: this.onChangeLanguage,
      ai: this.state.ai,
      user: this.state.user,
    }

    return <LoginContainer {...props} />
  }
}

export default App;
