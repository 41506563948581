import React, { Component } from 'react';
import translations from '../../../translations.json'
import './Teaser.scss';

export default class Teaser extends Component {

	render() {
		const { activeLanguage } = this.props;
		return (<React.Fragment>
				<section className="df-login-sidebar-teaser teaser-element">
					<h2 className="df-login-sidebar-teaser-title">
						{translations[this.props.activeLanguage].values['teaser_headline']}
					</h2>
					<div className="df-login-sidebar-body">
						<div className="df-login-sidebar-body-text-button">
							<p>
								{translations[activeLanguage].values['teaser_body']}
							</p>
							<a rel="noreferrer" href={translations[activeLanguage].values['teaser_ctaURL']} target="_blank"
							   className="df-login-sidebar-teaser-cta mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent df-button df-button--with-caret">
								{translations[activeLanguage].values['teaser_cta']}
							</a>
						</div>
						<div className="df-login-sidebar-teaser-visual-container">
							<img alt="Analysis Teaser" src="https://www.fleet-intelligence.com/bi/v1/ext/DfC11Login/login/images/sidebar-teaser-visual.png"
								 className="df-login-sidebar-teaser-visual"/>
						</div>
					</div>
				</section>
				<section className="df-login-sidebar-analysis teaser-element">
					<h2 className="df-login-sidebar-teaser-title">
						{translations[activeLanguage].values['analysis_headline']}
					</h2>
					<div className="df-login-sidebar-body">
						<div className="df-login-sidebar-body-text-button">
							<p>
								{translations[activeLanguage].values['analysis_body']}
							</p>
							<a rel="noreferrer" href={translations[activeLanguage].values['analysis_ctaURL']} target="_blank"
							   className="df-login-sidebar-teaser-cta mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent df-button df-button--with-caret">
								{translations[activeLanguage].values['analysis_cta']}
							</a>
						</div>
						<div className="df-login-sidebar-teaser-visual-container">
							<img alt="Case study" src={translations[activeLanguage].values['analysis_imageUrl']} className="df-c11-login-sidebar-analysis-visual"/>
						</div>
					</div>
				</section>
			</React.Fragment>);
	}
}

