import React, { Component } from 'react';
import translations from '../../translations.json';
import links from '../../links.json';
import irisOnMac from '../../assets/images/iris-on-mac.png';
import './Login.scss';
import NewsContainer from './News/NewsContainer';
import Teaser from './Teaser/Teaser'


import LoaderButton from '../shared/LoaderButton'

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenLangPicker: false,
            isOpenContactPicker: false
        };
    }

    onClickLangPicker = (value) => {
        if ((value !== undefined) && this.state.isOpenLangPicker) this.setState({ isOpenLangPicker: !!value });
        this.setState(({ isOpenLangPicker }) => ({ isOpenLangPicker: !isOpenLangPicker }));
    }

    onClickContactPicker = (value) => {
        if ((value !== undefined) && this.state.isOpenContactPicker) this.setState({ isOpenContactPicker: !!value });
        this.setState(({ isOpenContactPicker }) => ({ isOpenContactPicker: !isOpenContactPicker }));
    }


    render() {
        const { activeLanguage, locale, isLoading, login, onChangeLanguage } = this.props;
        const { isOpenLangPicker, isOpenContactPicker  } = this.state;
        const { onClickLangPicker, onClickContactPicker } = this;
        return (
            <div className="login">
                <div className="login__container">
                    <header className="login__header">

                    </header>
                    <div className="login__content">
                        <div className="login__logo-wrapper">
                            <img src={require('../../assets/images/logo-iris.svg').default} alt='mySvgImage' />
                        </div>
                        <img className="login__product-image" src={irisOnMac} alt='IRIS on Mac' />

                        <h2 className="login__greeting">
                            {translations[activeLanguage].values['welcome_1']} <span className="login__greeting_bold">DataForce</span>, {translations[activeLanguage].values['welcome_2']}
                        </h2>
                        <div className="login__button">
                            <LoaderButton
                                onClick={() => login()}
                                key={isLoading}
                                isLoading={isLoading}
                                text={translations[activeLanguage].values['login']}
                                loadingText={translations[activeLanguage].values['loading']}
                            />
                        </div>
                    </div>
                    <footer className="login__footer">
                        <div className="login__footer-left-side">
                            <a href={links[activeLanguage].values['imprint']} className="login__gray_item">{translations[activeLanguage].values['imprint']}</a>
                            <a href={links[activeLanguage].values['terms']} className="login__gray_item">{translations[activeLanguage].values['terms']}</a>
                            <a href={links[activeLanguage].values['privacy']} className="login__gray_item">{translations[activeLanguage].values['privacy']}</a>
                        </div>

                        <div className="login__footer-middle">
                            <div onClick={onClickContactPicker} className="login__cursor-pointer">
                                <div className="login__language-container">
                                    <div className="login__contact">{translations[activeLanguage].values['contact']}</div>

                                                <div
                                                    className={isOpenContactPicker ? "login__language-other_active" : "login__language-other"}
                                                >
                                                    <div className={isOpenContactPicker ? "login__language-other_active" : "login__language-other"} style={{top: '-20px'}}><a href={'tel:' + translations[activeLanguage].values['contactPhone']}>{translations[activeLanguage].values['contactPhone']}</a></div>
                                                    <div className={isOpenContactPicker ? "login__language-other_active" : "login__language-other"} style={{top: '-10px'}}><a href={'mailto:' + translations[activeLanguage].values['contactMail']}>{translations[activeLanguage].values['contactMail']}</a></div>

                                                </div>

                                </div>
                                <div className={isOpenContactPicker ? "login__lang-picker-triangle_active" : "login__lang-picker-triangle"}></div>
                            </div>
                        </div>

                        <div className="login__footer-right-side">
                            <p className="login__gray_item">{translations[activeLanguage].values['language']}: </p>
                            <div onClick={onClickLangPicker} className="login__cursor-pointer">
                                <div className="login__language-container">
                                    <div onClick={() => onChangeLanguage(activeLanguage)} className="login__language">{translations[activeLanguage].name}</div>
                                    {
                                        translations.map(({ name }) => name).reduce((accum, lang, idx) => idx !== activeLanguage ? [...accum, { lang, idx }] : accum, [])
                                            .map(({ lang, idx }, index) => (
                                                <div
                                                    key={lang}
                                                    className={isOpenLangPicker ? "login__language-other_active" : "login__language-other"}
                                                    style={{ top: `${isOpenLangPicker ? -10 * ++index : 0}px` }}
                                                    onClick={() => onChangeLanguage(idx)}
                                                >
                                                    {lang}
                                                </div>
                                            ))
                                    }
                                </div>
                                <div className={isOpenLangPicker ? "login__lang-picker-triangle_active" : "login__lang-picker-triangle"}></div>
                            </div>
                        </div>
                    </footer>
                </div>
                <div className="sidebar__container">
                    <div className="teaser__container">
                        <Teaser activeLanguage={activeLanguage} />
                    </div>
                    <div className="news__container">
                        <NewsContainer
                            locale={locale}
                            activeLanguage={activeLanguage}
                        />
                    </div>
                </div>
            </div>
        )
    }
}