import React, { Component } from 'react';
import News from './News.js';

class NewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: { nat: [], int: [] },
      newsCurrentType :  (this.props.locale === "en") ? 'int' : 'nat'
    };
  }

  componentDidMount() {
    fetch('https://www.dataforce.de/wp-json/wp/v2/posts?per_page=20&&_fields=id,title,link,date_gmt&categories=1,25,348,366,369,371')
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          news: {
            ...this.state.news,
            nat: data
          }
        });
      })
      .catch(function(error) {
        console.log('News', error);
      });

    fetch('https://www.dataforce.de/en/wp-json/wp/v2/posts?per_page=20&&_fields=id,title,link,date_gmt')
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          news: {
            ...this.state.news,
            int: data
          }
        });
      })
      .catch(function(error) {
        console.log('newsInternational', error);
      });
  }

  changeTableNews = () => {
    this.setState(previousState => {
      switch (previousState.newsCurrentType) {
        case 'nat':
          return { newsCurrentType: 'int' };
        case 'int':
          return { newsCurrentType: 'nat' };
        default:
          return { newsCurrentType: 'int' };
      }
    });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.locale !== prevProps.locale) {
      if (this.props.locale === "en"){
        this.setState({newsCurrentType : 'int'});
      } else {
        this.setState({newsCurrentType : 'nat'});
      }
    }
  }

  render() {
    const {
      newsCurrentType,
      news
    } = this.state;

    return (
          <div className='info'>
            <section className="info__news">
                <News
                  locale={this.props.locale}
                  toggleStates={[{ name: 'National', id: 'nat' },{ name: 'International', id: 'int' }]}
                  news={news[newsCurrentType]}
                  activeLanguage={this.props.activeLanguage}
                  activeToggleState={newsCurrentType}
                  onToggle={this.changeTableNews}
                />
            </section>
      </div>
    );
  }
}

export default NewsContainer;
