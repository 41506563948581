import React, { Component } from 'react';

import Login from './Login'

export default class LoginContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({
            isLoading: false,
        });
    }

    login = () => {
        this.setState({
            isLoading: true,
        });
        const env = process.env;

        // Source: https://stackoverflow.com/a/43043658
        // Fixes the loading spinner on the Login button to spin after going back via browser history
        window.addEventListener( "pageshow", function ( event ) {
            var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
            if ( historyTraversal ) {
                // Handle page restore.
                window.location.reload();
            }
        });

        switch (env.REACT_APP_IP_MODE) {
            case "test":
                window.location.href = env.REACT_APP_TEST_LOGIN_URL;
                break;
            case "test112":
                window.location.href = env.REACT_APP_TEST112_LOGIN_URL;
                break;
            case "chk":
                window.location.href = env.REACT_APP_CHK_LOGIN_URL;
                break;
            case "internal":
                window.location.href = env.REACT_APP_INTERNAL_LOGIN_URL;
                break;
            default:
                window.location.href = env.REACT_APP_PROD_LOGIN_URL;
                break;
        }

    }


    render() {

        const loginProps = {
            login: this.login,
            isLoading: this.state.isLoading,
            onChangeLanguage: this.props.onChangeLanguage,
            activeLanguage: this.props.activeLanguage,
            locale: this.props.locale
        }

        return (
            <Login {...loginProps}/>
        )
    }
}